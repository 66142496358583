@tailwind utilities;

@layer utilities {
  .flex-cc {
    @apply flex justify-center items-center;
  }
  .flex-cs {
    @apply flex justify-center items-start;
  }
  .flex-ce {
    @apply flex justify-center items-end;
  }

  .flex-bc {
    @apply flex justify-between items-center;
  }
  .flex-bs {
    @apply flex justify-between items-start;
  }
  .flex-be {
    @apply flex justify-between items-end;
  }

  .flex-sc {
    @apply flex justify-start items-center;
  }
  .flex-ss {
    @apply flex justify-start items-start;
  }
  .flex-se {
    @apply flex justify-start items-end;
  }

  .flex-ec {
    @apply flex justify-end items-center;
  }
  .flex-es {
    @apply flex justify-end items-start;
  }
  .flex-ee {
    @apply flex justify-end items-end;
  }

  .flex-vc {
    @apply flex justify-evenly items-center;
  }
  .flex-vs {
    @apply flex justify-evenly items-start;
  }
  .flex-ve {
    @apply flex justify-evenly items-end;
  }

  .flex-ac {
    @apply flex justify-around items-center;
  }
  .flex-as {
    @apply flex justify-around items-start;
  }
  .flex-ae {
    @apply flex justify-around items-end;
  }
  .col {
    @apply flex-col;
  }
  .full {
    @apply w-full h-full;
  }
  .fullscreen {
    @apply fixed full inset-0;
  }
  .stroked {
    -webkit-text-stroke: 0.4px;
  }
  .dashed-outline {
    @apply outline-1 outline-dashed outline-offset-2 outline-muted-200;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
