@tailwind components;

@layer components {
  /* Built in loading state of every button */
  button[data-loading='true'] {
    @apply flex justify-center items-center;
  }

  button[data-loading='true'] i.spinner {
    @apply inline-flex ml-2;
  }

  .container {
    @apply w-full max-w-[1200px] mx-auto;
  }

  i.spinner {
    display: none;
    width: 16px;
    height: 16px;
    border: 3px solid;
    border-color: #fff #fff5 #fff #fff5;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .work-list-with-title a:nth-child(1),
  .work-list-with-title a:nth-child(4) {
    @apply md:col-span-2;
  }

  input[type='checkbox'].custom-checkbox {
    @apply appearance-none m-0 grid cursor-pointer place-content-center;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    font: inherit;
    color: #e9ebed;
    width: 24px;
    height: 24px;
    border: 0.15em solid #e9ebed;
    border-radius: 5px;
    transform: translateY(-0.075em);
  }

  input[type='checkbox'].custom-checkbox:checked {
    @apply bg-primary border-primary;
  }

  input[type='checkbox'].custom-checkbox::before {
    @apply scale-0 origin-bottom-left;
    content: '';
    width: 12px;
    height: 12px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transition: 120ms transform ease-in-out;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='checkbox'].custom-checkbox:checked::before {
    @apply scale-100 bg-white;
  }

  .slimscroll::-webkit-scrollbar {
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
    width: 6px;
  }

  .slimscroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    transition-duration: 0.3s;
    -webkit-transition-property: all;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .menu-focus {
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px;
  }

  .menu-focus:focus-within {
    @apply outline-muted-300;
  }

  .main-button {
    @apply h-11 transition-all duration-300 flex justify-center items-center relative cursor-pointer rounded-md gap-x-1;
  }

  .main-button.primary {
    @apply active:outline-primary-500 bg-primary-500 hover:bg-primary-600 active:bg-primary-500 text-white;
  }

  .main-button.orange {
    @apply active:outline-orange-500 bg-orange-500 hover:bg-orange-600 active:bg-orange-500 text-white;
  }

  .main-button.light {
    @apply bg-white hover:bg-muted-200 text-muted-700 active:bg-white border border-muted-300;
  }

  .main-button.red {
    @apply active:outline-red-500 bg-red-500 hover:bg-red-600 active:bg-red-500 text-white;
  }

  .main-button.outlined.primary {
    @apply active:outline-primary-500 bg-primary-100 border border-primary-500 hover:bg-primary-200 active:bg-primary-100 text-primary-500;
  }

  .main-button.outlined.orange {
    @apply active:outline-orange-500 bg-orange-100 border border-orange-500 hover:bg-orange-200 active:bg-orange-100 text-orange-500;
  }

  .main-button.outlined.yellow {
    @apply active:outline-yellow-500 bg-yellow-100 border border-yellow-500 hover:bg-yellow-200 active:bg-yellow-100 text-yellow-500;
  }

  .main-button.outlined.red {
    @apply active:outline-red-500 bg-red-100 border border-red-500 hover:bg-red-200 active:bg-red-100 text-red-500;
  }

  .profile {
    @apply relative flex w-full aspect-[1/1] items-center justify-center rounded-full;
  }
  .profile.primary {
    @apply bg-primary-100 border-primary-500 text-primary-500;
  }
  .profile.purple {
    @apply bg-purple-100 border-purple-500 text-purple-500;
  }
  .profile.orange {
    @apply bg-orange-100 border-orange-500 text-orange-500;
  }
  .profile.blue {
    @apply bg-blue-100 border-blue-500 text-blue-500;
  }
  .profile.red {
    @apply bg-red-100 border-red-500 text-red-500;
  }
  .profile.yellow {
    @apply bg-yellow-100 border-yellow-500 text-yellow-500;
  }
  .profile.pink {
    @apply bg-pink-100 border-pink-500 text-pink-500;
  }

  .animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: rgb(241, 245, 249);
    background: linear-gradient(
      to right,
      rgb(241, 245, 249) 8%,
      rgb(226, 232, 240) 18%,
      rgb(241, 245, 249) 33%
    );
    background-size: 800px 104px;
    height: 96px;
    position: relative;
  }
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  .animated-dot-colors {
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: dotColorChange;
  }
  @keyframes dotColorChange {
    25% {
      @apply text-yellow-500;
    }
    50% {
      @apply text-orange-500;
    }
    75% {
      @apply text-red-500;
    }
    100% {
      @apply text-orange-500;
    }
  }

  .slimscroll::-webkit-scrollbar {
    @apply -md:hidden;
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
    height: 7px;
  }

  .slimscroll::-webkit-scrollbar-thumb {
    @apply bg-black/10 hover:bg-black/20;
    border-radius: 0.5rem;
    transition-duration: 0.3s;
    -webkit-transition-property: all;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
