@tailwind base;

@layer base {
  * {
    @apply box-border;
  }

  html,
  body {
    @apply m-0 p-0 text-black bg-white font-main overflow-x-clip;
    scroll-behavior: smooth;
  }

  @media (max-width: 767px) {
    html,
    body,
    main,
    div {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
    }

    html::-webkit-scrollbar,
    body::-webkit-scrollbar,
    main::-webkit-scrollbar,
    div::-webkit-scrollbar {
      display: none !important;
    }
  }

  a {
    @apply no-underline;
  }

  button,
  input,
  textarea,
  select {
    @apply focus:outline-none;
  }

  input,
  .custom-outline {
    @apply outline-dashed outline-offset-2 outline-1 outline-transparent focus:outline-dashed focus:outline-offset-2 focus:outline-1 focus:outline-muted-300;
  }

  input[type='file'] {
    @apply !px-2;
  }
}
